<!--
--------------------------------------------------------------------------
   ItemHits.vue
--------------------------------------------------------------------------

    Component to print the statistics of an item

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-hits" v-if="loading === 0">
        <div class="row">
            <div class="col-12 col-md-6">
                <b-table striped hover :items="statisticsLastMonths" :fields="fieldsStatisticsLastMonths" caption-top small>
                    <template #table-caption><translate>Hits by month</translate></template>
                </b-table>
            </div>
            <div class="col-12 col-md-6 justify-content-center align-self-center">
                <highcharts :constructorType="'mapChart'" class="hc" :options="chartOptions" ref="chart"></highcharts>
            </div>
        </div>
    </div>
</template>

<script>
    import HighchartsMapModule from 'highcharts/modules/map';
    import mapData from '@highcharts/map-collection/custom/world.geo.json';
    import { Chart } from 'highcharts-vue';
    import Highcharts from "highcharts";

    HighchartsMapModule(Highcharts);

    export default {
        name: 'item-hits',
        props: ['item'],
        components: { highcharts: Chart },
        data() {
            return {
                loading: -2,
                additionalUrls: '',
                statisticsLastMonths: [],
                fieldsStatisticsLastMonths: [
                    { key: 'year', label: this.$gettext('Year')},
                    { key: 'month', label: this.$gettext('Month')},
                    { key: 'pageviews', label: this.$gettext('Hits')},
                    { key: 'uniquePageviews', label: this.$gettext('Users')},
                    { key: 'avgSessionDuration', label: this.$gettext('Average time on page')}
                ],
                statisticsByCountry: [],
                monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                chartOptions: {
                    lang: {
                        resetZoom: this.$gettext('View all'),
                        resetZoomTitle: this.$gettext('View all'),
                        decimalPoint: ',',
                        thousandsSep: '.',
                        contextButtonTitle: this.$gettext('Menu'),
                        downloadJPEG: this.$gettext('Download JPEG'),
                        downloadPDF: this.$gettext('Download PDF'),
                        downloadPNG: this.$gettext('Download PNG'),
                        downloadSVG: this.$gettext('Download SVG'),
                        loading: this.$gettext('Loading...'),
                        printChart: this.$gettext('Print'),
                        downloadCSV: this.$gettext('Download CSV'),
                        downloadXLS: this.$gettext('Download XLS (Excel)'),
                        viewData: this.$gettext('View data table'),
                        viewFullscreen: this.$gettext('View in full screen'),
                        hideData: this.$gettext('Hide data table'),
                        exitFullscreen: this.$gettext('Exit from full screen'),
                        numericSymbols: null
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: ["printChart",
                                    "separator",
                                    "downloadPNG",
                                    "downloadJPEG",
                                    "downloadPDF",
                                    "downloadSVG",
                                    "separator",
                                    "downloadCSV",
                                    "downloadXLS",
                                    //"viewData",
                                    "openInCloud"]
                            }
                        }
                    },
                    chart: {
                        map: mapData,
                        style: {
                            fontFamily: '"Roboto", Helvetica, serif'
                        }
                    },
                    title: {
                        text: this.$gettext('Hits by country'),
                        style: { 'font-weight': 'bold', 'color': '#6c757d' },
                    },
                    legend: {
                        title: {
                            text: this.$gettext('Hits')
                        }
                    },
                    mapNavigation: {
                        enabled: true
                    },
                    colorAxis: {
                        min: 0,
                        minColor: '#fff7e5',
                        maxColor: '#e2ab17'
                    },
                    plotOptions: {
                        series: {
                            color: '#960b04'
                        },
                    },
                    series: [{
                        name: this.$gettext('Hits'),
                        states: {
                            hover: {
                                color: '#960b04'
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        },
                        data: []
                    }]
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Inits hits data
             */
            init() {
                // Get all item URLs
                let urls = this.getItemFieldValue(this.item, 'accessURL', this.$language.current, true);
                this.additionalUrls = urls.map(u => {
                    return u.split('##')[0].replace(this.$store.state.config.URL + '/', '');
                }).join(',');
                this.getStatisticsLastMonths();
                this.getStatisticsByCountry();
            },
            getStatisticsLastMonths() {
                // Get item global statistics
                this.axios.get(this.apiURL + '/statistics/' + this.item.id + '/global?additionalUrls=' + this.additionalUrls)
                    .then(response => {
                        let statistics = response.data;
                        let totals = { year: '', month: this.$gettext('TOTAL'), pageviews: 0, uniquePageviews: 0, avgSessionDuration: 0};
                        statistics.sort((a,b) => {
                            if (a.year < b.year) return 1;
                            else if (a.year > b.year) return -1;
                            else if (a.year == b.year && a.month < b.month) return 1;
                            else if (a.year == b.year && a.month >= b.month) return -1;
                        });
                        statistics.forEach(s => {
                            s.month = this.$gettext(this.monthNames[s.month * 1 - 1]);
                            totals.pageviews += s.pageviews;
                            totals.uniquePageviews += s.uniquePageviews;
                            totals.avgSessionDuration += (s.avgSessionDuration * 1);
                            s.avgSessionDuration = this.transformTime(s.avgSessionDuration);
                        });
                        totals.avgSessionDuration = this.transformTime(totals.avgSessionDuration / 12);
                        statistics.push(totals);
                        this.statisticsLastMonths = statistics;
                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            getStatisticsByCountry() {
                // Get item statistics by country
                this.axios.get(this.apiURL + '/statistics/' + this.item.id + '/country?additionalUrls=' + this.additionalUrls)
                    .then(response => {
                        let data = [];
                        response.data.forEach(d => {
                           data.push([d.country.toLowerCase(), d.pageviews]);
                        });
                        this.chartOptions.series[0].data = data;
                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        },
        watch: {
            item() {
                this.loading = -2;
                this.init();
            },
            loading() {
                // Tell parent to show tab  of hits
                if (this.loading === 0) this.$root.$emit('showHits');
            }
        }
    }
</script>